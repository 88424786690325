import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListProviders from "@/components/fornecedores/ListProviders.vue";

export default {
  components: {
    HeaderRegister,
    ListProviders,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
